import pProps from 'p-props'

export async function getParameterValuesForReport(mediator, selectedReportId) {
	const { parameters, parameterValues } = await pProps({
		parameters: mediator.call('emitToServer', 'load report parameter list', { reportId: selectedReportId, runParameterQueries: true }),
		parameterValues: mediator.call('emitToServer', 'load report parameter value list', { reportId: selectedReportId }),
	})

	if (parameterValues.length) {
		// Put the parameter values (per parameter) into an object for (Key) Choice parameters, and find the default value for all parameters
		const parameterOptions = parameterValues.reduce((acc, parameter) => {
			const { defaultValue: isDefault, value, reportParameterId } = parameter
			const parameterIndex = parameters.findIndex(parameter => parameter.reportParameterId === reportParameterId)

			if (!acc[reportParameterId]) {
				acc[reportParameterId] = {
					options: {},
				}
			}
			if (isDefault) {
				acc[reportParameterId].value = value
			}
			// Only need to add the options if it's a (Key) Choice parameter
			if (value && (parameters[parameterIndex].type === 'Choice' || parameters[parameterIndex].type === 'Key Choice')) {
				acc[reportParameterId].options[value] = value
			}
			return acc
		}, {})
		// Set the parameter values into the proper parameters
		Object.keys(parameterOptions).forEach(reportParameterId => {
			reportParameterId = parseInt(reportParameterId, 10)
			const { options, value } = parameterOptions[reportParameterId]

			const parameterIndex = parameters.findIndex(parameter => parameter.reportParameterId === reportParameterId)

			if (parameterIndex > -1 && (parameters[parameterIndex].type === 'Choice' || parameters[parameterIndex].type === 'Key Choice')) {
				if (parameters[parameterIndex].options) {
					parameters[parameterIndex].options = { ...options, ...parameters[parameterIndex].options }
				} else {
					parameters[parameterIndex].options = options
				}
				parameters[parameterIndex].value = value
			} else if (parameterIndex > -1) {
				parameters[parameterIndex].value = value
			}
		})
	}
	return parameters
}
export async function generatePdfPreview(mediator, { name, type, parameters }) {
	try {
		const preview = await mediator.call('emitToServer', 'get crystal report pdf', { name, type, parameters },
			{
				context: JSON.stringify({
					name,
					type,
					parameters,
				}, null, 4),
				doNotAlert: true,
			})
		return preview
	} catch (err) {
		console.error(err)
		throw err
	}
}
