import template from './preview.html'
import { getParameterValuesForReport, generatePdfPreview } from 'utility/report-viewer-helper'

//Ractive Components
import { makeReportViewerPreviewComponent } from '@isoftdata/report-viewer'
import makeReportSelection from 'components/report-selection'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter, logAndAlert, hasPermission }) {
	stateRouter.addState({
		name: 'app.report-viewer.preview',
		route: 'preview/:selectedReportId',
		querystringParameters: [ 'selectedReportId, selectedCategory' ],
		defaultParameters: {
			selectedReportId: null,
			selectedCategory: null,
		},
		template: {
			template,
			components: {
				itButton: makeButton(),
				reportSelectionModal: makeReportSelection({ mediator, logAndAlert }),
				reportViewerPreview: makeReportViewerPreviewComponent({
					generatePdfPreview: ({ name, type, parameters }) => {
						return generatePdfPreview(mediator, { name, type, parameters })
					},
				}),
			},
		},
		async resolve(data, { selectedCategory, selectedReportId, reportParameters = '{}', previewOnly = false }) {
			selectedReportId = parseInt(selectedReportId, 10)
			if (selectedReportId) {
				const parameters = await getParameterValuesForReport(mediator, selectedReportId)

				// If report parameters are passed via state parameters, apply them
				if (reportParameters) {
					try {
						reportParameters = JSON.parse(reportParameters)
						console.log('reportParameters', reportParameters)
						for (const key in reportParameters) {
							const parameterIndex = parameters.findIndex(parameter => parameter.name === key)
							if (parameterIndex > -1) {
								parameters[parameterIndex].value = reportParameters[key]
							}
						}
					} catch (err) {
						reportParameters = {}
					}
				}

				console.log('parameters', parameters)
				return { parameters, selectedReportId, selectedCategory, previewOnly }
			}

			return { selectedReportId, selectedCategory, previewOnly }
		},
		activate({ domApi: ractive }) {
			ractive.on('print', context => {
				const componentInstance = ractive.findComponent('reportViewerPreview')
				let parameters = componentInstance.get('parameters')
				const report = componentInstance.get('selectedReport')
				if (!parameters) {
					parameters = []
				} else {
					parameters = parameters.map(parameter => {
						const { name, value } = parameter
						return {
							parameterName: name,
							value,
						}
					})
				}

				ractive.findComponent('reportSelectionModal').printReport({
					type: report.type,
					name: report.name,
					reportParameters: parameters,
				}, {}, { hidePreviewOption: true })
			})

			if (ractive.get('preview')) {
				const rv = ractive.findComponent('reportViewerPreview')
				const selectedReport = rv.get('selectedReport')
				const parameters = rv.get('parametersToSend')
				rv.fire('preview-cancel-report-clicked', {}, selectedReport, parameters)
			}
		},
	})
}
