import template from './report-viewer.html'

//Ractive Components
import { makeReportViewerComponent } from '@isoftdata/report-viewer'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.report-viewer',
		route: 'report-viewer',
		querystringParameters: [ 'selectedCategory', 'selectedReportId' ],
		defaultParameters: {
			selectedReportId: null,
			selectedCategory: null,
		},
		defaultChild: 'preview',
		template: {
			template,
			components: {
				reportViewer: makeReportViewerComponent({ stateRouter }),
			},
		},
		async resolve(data, stateParameters) {
			stateParameters.selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null
			const reportList = (await mediator.call('emitToServer', 'load reports', { userVisible: true, includeAvgTime: true }))
				.map(parameter => {
					return { ...parameter, category: parameter.category || 'Uncategorized' }
				})
			// If they pass a category and report id that's not in the list, load and add it to the list so they can preview it.
			if ((stateParameters.selectedCategory || stateParameters.selectedCategory === '') && stateParameters.selectedReportId) {
				const missingReport = reportList.findIndex(report => report.reportId === stateParameters.selectedReportId) === -1
				if (missingReport) {
					const fetchedReport = (await mediator.call('emitToServer', 'load reports', { reportId: stateParameters.selectedReportId }))?.[0]
					if (fetchedReport) {
						reportList.push(fetchedReport)
					}
				}
			}
			return { reportList, ...stateParameters }
		},
		activate({ domApi: ractive }) {

		},
	})
}
